<template>
  <div>
    <vx-card>
      <vue-bootstrap4-table @showDraftInvoicePopup="draftInvoicePopup"
                            @showDraftCreditNotePopup="draftCreditNotePopup"
                            @on-select-row="onSelectRows"
                            @on-unselect-row="onSelectRows"
                            @on-all-unselect-rows="onSelectRows"
                            @on-all-select-rows="onSelectRows"
                            :rows="data"
                            :columns="columns"
                            :config="config"
                            :actions="actions"
                            @on-change-query="onChangeQuery"
                            :total-rows="total_rows"
                            columnSelection>
        <template slot="global_search-after" >
          <div class="ml-4 mt-2 flex">
            <vs-switch color="success" v-model="showAllPositionsButton" @change="fetchData()"/> <p class="ml-2">Inkl. abgerechnete Positionen anzeigen </p>
          </div>
          <template>
            <div class="demo-alignment">
              <vs-popup class="holamundo" title="Rechnungsentwurf" :active.sync="draftPopupActive">
                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </vs-popup>
            </div>
          </template>


        </template>
        <template slot="order_id" slot-scope="props">
          <div v-if="props.row.order_id == null">
            <span class="mega--circle bg-gray-300 "></span>
          </div>
          <div v-if="props.row.order_id && props.row.order">
            <div v-if="props.row.order.invoice_number">
              <vx-tooltip :text="'Rechnung ' + props.row.order.invoice_number + ' / ' + formatDate(props.row.order.order_date)">
                <span class="mega--circle green"></span>
              </vx-tooltip>
            </div>

            <div v-if="!props.row.order.invoice_number">
              <vx-tooltip :text="'Rechnungsentwurf'">
                <span class="mega--circle yellow"></span>
              </vx-tooltip>
            </div>
          </div>



          <div></div>
        </template>

        <template slot="amount" slot-scope="props">
          <div class="text-center">
            {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
          </div>
        </template>

        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group" >

            <router-link  v-if="!props.row.order_id"  :to="'/accounting/'+props.row.id" >
              <vx-tooltip text="Bearbeiten">
                <vs-button color="warning" type="filled" icon="edit" size="small"
                           :to="'/accounting/'+props.row.id" ></vs-button>
              </vx-tooltip>
            </router-link>

            <router-link  v-if="props.row.order_id"  :to="'/accounting/'+props.row.id" >
              <vx-tooltip text="Ansehen" >
                <vs-button color="dark" type="filled" icon="visibility" size="small"
                           :to="'/accounting/'+props.row.id"  ></vs-button>
              </vx-tooltip>
            </router-link>

            <vx-tooltip text="Löschen"  >
              <vs-button color="primary"
                         type="filled"
                         icon="delete_forever"
                         size="small"  @click="openDeleteConfirm(props.row.id)" v-if="!props.row.order_id"></vs-button>
            </vx-tooltip>
          </div>
        </template>

        <template slot="article_name" slot-scope="props">
          <div class="mt-2">  {{ props.row.article_name }}</div>

          <div class="short_description" v-if="props.row.short_description" v-html="props.row.short_description">
            {{ props.row.short_description }}
          </div>
          <div class="column-actions-group flex" v-if="props.row.accounting_start != null && props.row.accounting_end !=null && props.row.contract_start !=null && props.row.contract_end !=null">

            <vx-tooltip  position="bottom">
              <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
              <template v-slot:text>
                Abrechnungszeitraum: <br>{{formatDate(props.row.accounting_start) }} - {{ formatDate(props.row.accounting_end)}}<br>
                Vertragslaufzeit {{formatDate(props.row.contract_start)}} - {{formatDate(props.row.contract_end)}}
              </template>
            </vx-tooltip>

            <vx-tooltip text="Zum Vertrag >"  position="top">
              <div v-if="props.row.contract_id != null">
                <vs-button  type="flat"
                            color="warning"
                            icon="description"
                            size="small"
                            target="_blank"
                            class="padding-0"
                            :to="'/contracts/'+props.row.contract_id">
                </vs-button>
              </div>

            </vx-tooltip>
          </div>
        </template>

        <template slot="total_price" slot-scope="props">
          <div class="text-center">
            {{ props.row.unit_price * props.row.amount}} €
          </div>
        </template>
        <template slot="unit_price" slot-scope="props">
          <div class="text-center" >
            {{ props.row.unit_price }} €
          </div>
        </template>

        <template slot="article" slot-scope="props">
          <div class="text-center" v-if="props.row.article.ordernumber">
            {{ props.row.article.ordernumber}}
          </div>
        </template>

        <template slot="created_at" slot-scope="props">
          {{formatDate(props.row.created_at)}}
          <small v-if="props.row.creator"> <br>von  {{ props.row.creator.detail.first_name}} {{ props.row.creator.detail.last_name}}</small>

        </template>

        <template slot="invoice_number" slot-scope="props">
          <div v-if="props.row.order_id == null">
            -
          </div>
          <div v-if="props.row.order_id != null">
            <span v-if="props.row.order.invoice_number == null">
              Rechnungsentwurf
            </span>
            <span v-if="props.row.order.invoice_number ">
              <vx-tooltip :text="'Rechnung ' + props.row.order.invoice_number + ' / ' + formatDate(props.row.order.order_date)">
                 <router-link  v-if="props.row.order.invoice_number != null"  :to="'/invoices/view/'+props.row.order_id" >{{props.row.order.invoice_number}}</router-link>
              </vx-tooltip>
            </span>
          </div>
        </template>

        <template slot="commission_complete" slot-scope="props">
          <div v-if="props.row.commission_option === 1" class="commission_complete_circle">
            <vx-tooltip :text="'Aufgabenverteilung komplett abgeschlossen'">
              <span v-if="props.row.commission_complete == 1" class="mega--circle green"></span>
            </vx-tooltip>
            <vx-tooltip :text="'Aufgabenverteilung nicht abgeschlossen'">
              <span v-if="props.row.commission_complete != 1" class="mega--circle bg-gray-300 "></span>
            </vx-tooltip>
          </div>

          <div class="least_performance">
            <span v-if="props.row.commission_option === 1">{{ least_performance(props) }}%</span>
            <small v-if="props.row.commission_option === 0">Keine Aufgabenverteilung möglich</small>
          </div>
        </template>


        <template slot="commission_complete1" slot-scope="props">
          <div v-if="props.commission_complete == 1">
            <span class="mega--circle green"></span>
          </div>
          <div v-else>
            <span class="mega--circle bg-gray-300 "></span>
          </div>
        </template>

        <template slot="at_least_date_accounting" slot-scope="props">
          <span v-if="props.row.at_least_date_accounting">
            <b style="color: darkred">{{formatDate(props.row.at_least_date_accounting)}}</b>
          </span>
          <span v-else>-</span>
        </template>

        <template slot="offer" slot-scope="props">
          <span v-if="props.row.offer || props.row.offerItem">
           <small v-if="props.row.offer">Angebot #{{props.row.offer.invoice_number}} <br> {{props.row.offer.title}}</small>
           <small v-if="props.row.offerItem">{{props.row.offer.product_name}}</small>
          </span>
          <span v-else>-</span>
        </template>

        <template slot="empty-results">
          Keine abzurechnenden Positionen gefunden.
        </template>

        <template slot="table-last-row">
          <tr class="vbt-row-summary" v-if="userHasPermission('edit_open_orders')">
            <td colspan="3">
              <span class="vbt-table-td">{{total_rows}} Ergebnisse</span>
            </td>
            <td colspan="4" align="right"></td>
            <td colspan="2">
              <span class="vbt-table-td"><b>Gesamt: {{pageSum}}</b></span>
            </td>
          </tr>
        </template>

      </vue-bootstrap4-table>
    </vx-card>

    <vs-popup  :title="selectedData.length + ' Positionen abrechnen?'" :active.sync="isdraftCreditNotePopupOpen">
      <vs-row>
        <p>Möchten Sie die ausgewählten Positionen in einen Gutschriftsentwurf überführen (nicht rückgängig machbar). </p>
      </vs-row>
        <vs-row v-if="hasCreditPositions">
            <div class="vs-alert con-icon offerError mb-4"><i class="">Bitte beachte folgendes:</i><br>Es sollten nun ausschließlich Gutschriftspositionen ausgewählt sein. <br>Wenn du eine Rechnung mit ein oder mehreren Gutschriftspositionen erstellen möchtest, musst du auf "Rechnung erstellen" klicken. Hier wird explizit eine Gutschrift erstellt. </div>
        </vs-row>
        <vs-row v-if="!hasCreditPositions">
            <div class="vs-alert con-icon offerError mb-4"><i class="">Komisch</i><br>Du hast keine negativen Positionen ausgewählt.</div>
        </vs-row>
      <vs-row v-if="atLeastDateAccounting">
        <div class="vs-alert con-icon offerError mb-4"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Mind. eine Position die erst ab dem <b>{{ atLeastDateAccounting }}</b> abgerechnet werden soll.</div>
      </vs-row>
      <vs-button class="ml-3 mb-4 float-right" @click="createInvoice(11)" v-if="!atLeastDateAccounting">Ja Gutschrift erstellen</vs-button>
      <vs-row>
      </vs-row>
    </vs-popup>

      <vs-popup  :title="selectedData.length + ' Positionen abrechnen?'" :active.sync="isdraftInvoicePopupOpen">
          <vs-row>
              <p>Möchten Sie die ausgewählten Positionen in einen Rechnungsentwurf überführen (nicht rückgängig machbar). </p>
          </vs-row>
          <vs-row v-if="hasCreditPositions">
              <div class="vs-alert con-icon offerError mb-4"><i class="">Bitte beachte folgendes:</i><br>Es sind entweder ausschließlich ODER teilweiße Gutschriftspositionen ausgewählt. Es wird nun wie folgt vor gegangen:<br><br>1. Wenn nur Minus-Positionen ausgewählt sind, dann wird eine Gutschrift erstellt <br> 2. Wenn Minus und Plus Positionen ausgewählt sind, wird eine Rechnung erstellt und die Minuspositionen reduzieren die Gesamtsumme. </div>
          </vs-row>
          <vs-row v-if="atLeastDateAccounting">
              <div class="vs-alert con-icon offerError mb-4"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Mind. eine Position die erst ab dem <b>{{ atLeastDateAccounting }}</b> abgerechnet werden soll.</div>
          </vs-row>
          <vs-button class="ml-3 mb-4 float-right" @click="createInvoice(7)" v-if="!atLeastDateAccounting">Ja Rechnungen erstellen</vs-button>
          <vs-row>
          </vs-row>
      </vs-popup>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";

import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import PriceHelper from "../../../mixins/helper/price.helper";
import staticOptions from "../../../mixins/static/options";
import {mapGetters} from "vuex";

var qs = require('qs');

export default {
  components: {
    VueBootstrap4Table
  },
  data() {
    return {
      draftPopupActive: false,
      sendPopupActive: false,
      showAllPositionsButton: false,
      selectedData: [],
      data: [],
      atLeastDateAccounting: null,
      isdraftInvoicePopupOpen: false,
        isdraftCreditNotePopupOpen: false,

      actions: [

        {
          btn_text: "Hinzufügen",
          router_link: '/accounting/add',
          color: 'success'
        },
        {
          btn_text: "Rechnungsentwurf erstellen",
          event_name: 'showDraftInvoicePopup'
        },{
          btn_text: "Gutschrift erstellen",
          event_name: 'showDraftCreditNotePopup'
        }
      ],
      columns: [
        {
          label: "id",
          name: "id",
          showCol: false,
          hide: true,
          uniqueId: true
        },
        {
          label: "Abg.",
          name: "order_id",
          slot_name: "order_id",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          column_classes:"column-header-order"
        },
        {
          label: "Produktname",
          name: "article_name",
          slot_name: "article_name",
          sort: false,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Artikel",
          name: "article.ordernumber",
          slot_name: "article",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        /*  {
            label: "Rechnungsnummer",
            name: "order.invoice_number",
            slot_name: "invoice_number",
            sort: false,
            row_text_alignment: 'text-left',
            column_text_alignment: 'text-left',
            showCol: false,
          },*/
        {
          label: "Menge",
          name: "amount",
          slot_name: "amount",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          column_classes:"column-header-amount"
        },
        {
          label: "Einzelpreis",
          name: "unit_price",
          slot_name: "unit_price",

          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Gesamtpreis",
          name: "unit_price",
          slot_name: "total_price",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Kunde",
          name: "client.company_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Erstellt",
          name: "created_at",
          slot_name: "created_at",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: this.formatDateTime,
          initial_sort: true, //
          initial_sort_order: "asc" //
        },
        {
          label: "Abrechnen ab",
          name: "at_least_date_accounting",
          slot_name: "at_least_date_accounting",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
          formatter: this.formatDateTime
        },
        {
          label: "Aufgabenverteilung",
          name: "commission_complete",
          slot_name: "commission_complete",
          sort: true,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: false,
        },

        {
          label: "Angebot",
          name: "offer_id",
          slot_name: "offer",
          sort: false,
          row_text_alignment: 'text-center',
          column_text_alignment: 'text-center',
          showCol: false,
        },

        {
          label: "Actions",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'actions-column',
          showCol: true,
        }],
      config: {
        per_page: 30,
        checkbox_rows: true,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true,
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 30,
        page: 1,
      },
      total_rows: 0,
      selectedForDialog: null,
        hasCreditPositions: false,
    }
  },
  created() {
    //this.fetchData()
  },
  computed: {
    ...mapGetters(['userHasPermission']),
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);
    },
    allPositions() {
      return this.showAllPositionsButton;
    },

    pageSum() {
      if(!this.data) {
        return PriceHelper.formatCurrency(0);
      }

      const sum = this.data.reduce((acc, item) => {
        return acc + (item.amount * item.unit_price);
      }, 0);

      return PriceHelper.formatCurrency(sum);
    }

  },
  methods: {
      checkHasCreditPositions() {

          for(let i = 0;i < this.selectedData.length; i++) {

              if (this.isNegativ(this.selectedData[i].unit_price)){
                  return this.hasCreditPositions = true;
              }
          }
          this.hasCreditPositions = false;
      },
      isNegativ(number){
          return number < 0;
      },
    least_performance(props) {
      let percent = 0;
      for(let i = 0;i < props.row.commision_item.length; i++) {
        percent = percent + props.row.commision_item[i].percent_performance;
      }
      return percent;
    },
    draftInvoicePopup(){
          this.checkHasCreditPositions()

      if(this.selectedData.length === 0) {
        return this.$vs.notify({
          title: 'Du musst schon eine Position auswählen ;-)',
        });
      }
      this.atLeastDateAccounting = null;
      for(let i = 0;i < this.selectedData.length; i++) {
        if(this.selectedData[i].order_id) {
          return this.$vs.notify({
            title: 'Du kannst nicht eine bereits abgerechnete Position in einen Rechnungsentwurf überführen',
          });
        }
        if (this.selectedData[i].at_least_date_accounting && this.selectedData[i].at_least_date_accounting >= moment(Date()).format('YYYY-MM-DD')) {
          this.atLeastDateAccounting = this.formatDate(this.selectedData[i].at_least_date_accounting);
        }
      }

      this.isdraftInvoicePopupOpen = true;

    },
      draftCreditNotePopup(){
          this.checkHasCreditPositions()

      if(this.selectedData.length === 0) {
        return this.$vs.notify({
          title: 'Du musst schon eine Position auswählen ;-)',
        });
      }
      this.atLeastDateAccounting = null;
      for(let i = 0;i < this.selectedData.length; i++) {
        if(this.selectedData[i].order_id) {
          return this.$vs.notify({
            title: 'Du kannst nicht eine bereits abgerechnete Position in einen Rechnungsentwurf überführen',
          });
        }
        if (this.selectedData[i].at_least_date_accounting && this.selectedData[i].at_least_date_accounting >= moment(Date()).format('YYYY-MM-DD')) {
          this.atLeastDateAccounting = this.formatDate(this.selectedData[i].at_least_date_accounting);
        }
      }

      this.isdraftCreditNotePopupOpen = true;

    },
    onSelectRows(payload) {
      this.selectedData = []
      this.selectedData = payload.selected_items
    },
    createInvoice(orderTypeId) {
      this.$vs.loading()

      ApiService.post('orders/Accountings2OrderDraft/'+orderTypeId, this.selectedData).then((response) => {

        //   this.data = response.data.result;
        // this.total_rows = response.data.total;
        this.fetchData();
        this.$vs.loading.close()


        if(response.data.status === 'success') {
          setTimeout(function () {  this.$router.go(this.$router.currentRoute) }.bind(this), 1000)
          return this.$vs.notify({
            title: 'Rechnungsentwürfe erfolgreich erstellt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });

        } else {

          return this.$vs.notify({
            title: 'Fehlgeschlagen',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }).catch((error) => {
        this.$vs.loading.close()
        return this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

    },

    async fetchData() {
      this.$vs.loading()

      var requestParams = JSON.parse(JSON.stringify(this.requestParams))
      if(this.allPositions === false) {
        requestParams.order_id = "NULL";
      }
      ApiService.get('accountings', {
        params: requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close()
      })
    },
    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass diese Position gelöscht werden soll?',
        accept: this.deleteItem,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },

    deleteItem() {

      if (!this.selectedForDialog) {
        return
      }
      this.$vs.loading();
      ApiService.delete('accountings/' + this.selectedForDialog).then((response) => {

        this.selectedForDialog = null;
        this.fetchData();

        if(response.data.CommisionItems == true) {
          this.$vs.notify({
            title: 'Es wurden eine oder mehrere Provisionen gelöscht, bitte Mitarbeiter informieren',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          });
        }

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Position konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Position konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY - H:m')
    },
    getVolumeUnitNameById(id) {
      const units = staticOptions.units.find(item => item.value === id);
      return units.labelShort
    },
  },
  mounted() {
  }
}

</script>

<style lang="scss">
.column-header-order {
  padding-left: 0px!important;
  padding-right: 0px!important;
  .column-header-actions {
    display:none;
  }
}
.vs-button.padding-0.includeIconOnly.small {
  padding: 0!important;
  width: auto!important;
  height: auto!important;
  background: transparent!important;
}
.table-responsive .short_description {
  font-size: 10px;
  color: #a5a5a5;
  max-height: 65px;
  overflow: hidden;
  margin-top: 2px;
  margin-bottom: 2px;
}
.commission_complete_circle {
  float: left;
}

.least_performance {
  float: left;
  margin-left: 20px;
}
tr.vbt-row-selected {
  background: #e6fff1!important;
}

.vs-alert {
  background: #e24733;
  width: 100%;
  color: #fff;
  border-radius: 4px;
  border-width: 1px;
  margin-top: 10px;
}

</style>
